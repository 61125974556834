import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: "Message sent successfully" });
    } else {
      setStatus({
        succes: false,
        message: "Something went wrong, please try again later.",
      });
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <table>
                    <Row>
                      <Col size={16} className="px-1">
                        <h2>Ready to schedule an appointment?</h2>
                        <p>
                          Contact me today to schedule your first visit and get
                          started on the path to better physical health.
                        </p>
                        <p>
                          Call or text me at the phone number above, or send me
                          a message on WhatsApp or Instagram. You can also email
                          me at{" "}
                          <a href="mailto:dr.sakshi.physio@gmail.com">
                            dr.sakshi.physio@gmail.com
                          </a>
                          .
                        </p>

                        <h2>Contact Information</h2>
                        <ul>
                          <li>Phone No.: 9599556569 (Call or text)</li>
                          <li>WhatsApp: 9599556569 (Message)</li>
                          <li>
                            Email:{" "}
                            <a href="mailto:dr.sakshi.physio@gmail.com">
                              dr.sakshi.physio@gmail.com
                            </a>
                          </li>
                          <li>
                            Instagram:{" "}
                            <a href="https://www.instagram.com/dr.sakshi.physio/">
                              dr.sakshi.physio
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col size={12} className="px-1">
                        <h3>Clinic Information</h3>
                        <ul>
                          <li>Name: PAL Physiotherapy Center</li>
                          <li>
                            Address: Dispensary GH, C-Block, POWERGRID TOWNSHIP,
                            1 A, near Park, Sector 43, Gurugram, Haryana 122009
                          </li>
                          <li>Timing: Monday to Saturday, 9 AM to 1 PM</li>
                        </ul>
                        <p>
                          To book an appointment, please call or text me at the
                          above phone number, or send me a message on WhatsApp
                          or Instagram. You can also email me at{" "}
                          <a href="mailto:dr.sakshi.physio@gmail.com">
                            dr.sakshi.physio@gmail.com
                          </a>
                          .
                        </p>
                        <p>
                          If you prefer to visit me at my clinic, please note
                          the above address and timings. You can use the Google
                          Map link below for directions.
                        </p>
                        <p>
                          Google Map:{" "}
                          <a href="https://goo.gl/maps/Sax5w2Z3H33YBtiS7">
                            https://goo.gl/maps/Sax5w2Z3H33YBtiS7
                          </a>
                        </p>
                        <br/>
                        <p>
                          Thank you for considering me as your physiotherapist.
                          I look forward to hearing from you and helping you
                          achieve your optimal physical function and health.
                        </p>
                      </Col>
                    </Row>
                  </table>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
