import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import exercise from "../assets/img/exercise2.png";
import tape from "../assets/img/tape.jpeg";
import cupping from "../assets/img/cupping.jpeg";
import iastm from "../assets/img/iastm.jpeg";
import electro from "../assets/img/electro.jpeg";
import manual from "../assets/img/manual.jpeg";
import needle from "../assets/img/needle.webp";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Skills= () => {
  const projects = [
    {
      title: "Exercise Therapy",
      description:
        "Exercise therapy consists of movements and physical activities that are used to increase strength, function, and reduce discomfort",
      imgUrl: exercise,
    },
    {
      title: "Electro Therapy",
      description:
        "Electrotherapy includes a range of treatments using electricity to reduce pain, improve circulation, repair tissues, strengthen muscles, and promote bone growth, leading to improvements in physical functioning",
      imgUrl: electro,
    },
    {
      title: "IASTM",
      description:
        "Instrument Assisted Soft Tissue Manipulation is a skilled intervention that includes the use of specialized tools to manipulate the skin, myofascia, muscles, and tendons by various direct compressive stroke techniques",
      imgUrl: iastm,
    },
    {
      title: "Cupping Therapy",
      description:
        "Cupping therapy is an ancient form of alternative medicine in which a therapist puts special cups on your skin for a few minutes to create suction. People get it for many purposes, including to help with pain, inflammation, blood flow, relaxation and well-being, and as a type of deep-tissue massage.",
      imgUrl: cupping,
    },
    {
      title: "Manual Therapy",
      description:
        "Manual therapy, or manipulative therapy, is a physical treatment primarily used by physical therapists, physiotherapists, occupational therapists to treat musculoskeletal pain and disability; it mostly includes kneading and manipulation of muscles, joint mobilization and joint manipulation.",
      imgUrl: manual,
    },
    {
      title: "Kinesio Taping",
      description:
        " The Kinesio Taping Method is a therapeutic taping technique which alleviates pain and facilitates lymphatic drainage by microscopically lifting the skin. This lifting affect forms convolutions in the skin increasing interstitial space and allowing for decreased inflammation in affected areas",
      imgUrl: tape,
    },
    {
      title: "Dry Needling",
      description:
        "Trigger-point dry needling is an invasive procedure where a fine needle or acupuncture needle is inserted into the skin and muscle. It is aimed at myofascial trigger points (MTrP) which are hyperirritable spots in skeletal muscle that are associated with a hypersensitive palpable nodule in a taut band",
      imgUrl: needle,
    },
  ];

  return (
    <section className="project" id="skills">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Skills</h2>
                  <p>
                  My services include exercise therapy, electrotherapy, IASTM, cupping therapy, manual therapy, Kinesio Taping, and dry needling.<br/><br/> I will work with you to create a personalized treatment plan that meets your unique needs and goals.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
