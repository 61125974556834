import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Services } from "./components/Services";
import { Skills } from "./components/Skills";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Analytics } from "@vercel/analytics/react"
function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Services/>
      <Skills/>
      <Contact />
      <Footer />
      <Analytics/>
    </div>
  );
}

export default App;
