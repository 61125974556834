import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import homevisit from "../assets/img/homevisit.svg"
import clinic from "../assets/img/clinic.svg"
import online from "../assets/img/online.svg"
import event from "../assets/img/event2.svg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Services= () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Services</h2>
                        <p>Whether you prefer to receive treatment at my clinic, in the comfort of your own home, or online, I have you covered<br/><br/> In addition to my clinical skills, I am also a passionate sports enthusiast, and I offer on-site physiotherapy services for sports and other events. I am available to attend your event and provide support and treatment to athletes and participants as needed.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                             <img src={homevisit} alt="Image" /> 
                                <h5>Home Visits</h5>
                            </div>
                            <div className="item">
                                <img src={online} alt="Image" />
                                <h5>Online Consultation</h5>
                            </div>
                            <div className="item">
                                <img src={clinic} alt="Image" />
                                <h5>Clinic</h5>
                            </div>
                            <div className="item">
                                <img src={event} alt="Image" />
                                <h5>Sports and Other Event</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
